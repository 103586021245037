<template>
	<!--人脸认证 -->
    <div class="three-auth">
		<div class="main">
			<div class="item">
				<div class="name">真实姓名</div>
				<div class="inp">
					<input type="text" placeholder="请输入真实姓名" v-model="name">
				</div>
			</div>
			<div class="item">
				<div class="name">证件号码</div>
				<div class="inp">
					<input type="text" placeholder="请输入身份证号码" maxlength="18" v-model="idCard" >
				</div>
			</div>
			<div class="item">
				<div class="name">身份证有效期</div>
				<div class="inp" @click="pickerShow = true">
					<!-- <input type="text" maxlength="11" placeholder="请选择到期时间" v-model="endTime"> -->
					<span>{{endTime}}</span>
				</div>
			</div>

			<!-- 老人脸验证提交 -->
			<div class="submit" @click="submitAuth" v-if="oldSignFace">
				<zlButton text="下一步" />
			</div>
			<!-- e签宝人脸验证 -->
			<div class="submit" @click="eSignSubmit" v-if="eSignFace" data-type="eSign">
				<zlButton text="下一步" />
			</div>
			<div style="margin-top:10px" @click="handleSwitch" v-if="switchFlag">
				<zlButton text="切换三要素认证" color="#4D62B8" background="#fff" />
			</div>
		</div>
		<div class="picker">
			<van-popup v-model="pickerShow" position="bottom" round>
				<van-datetime-picker
					v-model="currentDate"
					type="date"
					title="选择年月日"
					:min-date="minDate"
					:max-date="maxDate"
					@confirm="confirmTime"
					@cancel="pickerShow=false"
				/>
			</van-popup>
	 	</div>
	</div>
</template>

<script>
import zlButton from "@/components/zlButton.vue";
import { dateFormat } from "@/utils/index";
import { getCheckPlatform } from "@/utils/cache"
import { eSignIndividualFace } from "@/api/user"
import { getAuthInfo } from "@/utils/auth"
export default {
	components: {
		zlButton
	},
	data() {
		return {
			title:"身份认证",
			backVisible:true,
			timeDown:60,
			endTime: "请选择到期时间", //2029-11-15
			idCard: "",
			name: "",
			pickerShow:false,
			minDate: new Date(1950, 0, 1),
			maxDate: new Date(2099, 11, 31),
			currentDate: new Date(),
			checkPlatform: "",
			eSignFace: false,
			oldSignFace: false,
			btnFlag: true,
			switchFlag: false,
		}
	},
	methods: {
		idCardInput(){
			let year;
			let month;
			let day;
			if(this.idCard.length == 15 ){
				// let  birthday = "19" + this.idCard.substr(6,6);
				year = "19" + this.idCard.substr(6,2);
				month = this.idCard.substr(8,2);
				day = this.idCard.substr(10,2);
				this.currentDate = new Date(year,month,day);
			}
			if(this.idCard.length == 18 ){
				// let  birthday = this.idCard.substr(6,8);
				year = this.idCard.substr(6,4);
				month = this.idCard.substr(10,2);
				day = this.idCard.substr(12,2);
				this.currentDate = new Date(year,month,day);
			}
		},
		confirmTime(val){
			this.endTime = dateFormat("YYYY-mm-dd", val);
			this.pickerShow = false;
		},
		///////  老人脸验证  /////////
		submitAuth() {
			let data = {
                idCard: this.idCard,
                name: this.name,
                expireEndDate: this.endTime,
			}
            if( !this.checkParams() ){
				return;
			}
			if( !this.btnFlag ){
				return;
			}
			this.btnFlag = false;
			setTimeout(()=>{
				this.btnFlag = true;
			},100)
			this.$router.push({
				path:'/ocrFace',
				query: data
			})
		},
		/////  e签宝人脸验证   //////
		eSignSubmit(){
			if( !this.checkParams() ){
				return;
			}
			let data = {
                idCard: this.idCard,
                name: this.name,
                expireEndDate: this.endTime,
				callbackUrl: this.faceCallbackUrl(),
				faceauthMode: "TENCENT",
			}
			//防止重复提交
            if( !this.btnFlag ){
				return;
			}
			this.btnFlag = false;
			eSignIndividualFace(data).then(res=>{
				this.btnFlag = true;
				console.log( JSON.stringify(res) );
				let { status,data,statusMessage } = res;
				if(status){
					if(data.isPass){
						this.$store.dispatch('user/setAuthInfo').then(res=>{
							this.$router.replace({
								path: "/authRes"
							})
						})
					}else{
						window.location.href = data.authUrl;
					}
				}else{
					this.$toast({
						message: statusMessage
					})
				}
			}).catch(err=>{
				this.btnFlag = true;
			})

		},
		faceCallbackUrl(){
            let origin = window.location.origin;
            // let url = encodeURIComponent(origin + "/#/faceTransfer");
            let url = origin + "/#/faceTransfer";
			console.log(origin + "/#/faceTransfer");
            return url;
        },
		//
		handleSwitch(){
			this.$router.push({
				path: "/threeAuth"
			})
			return;
		},
		checkParams(){
			// this.name = this.name.replace(/\s*/g,"");
			let data = {
                idCard: this.idCard,
                name: this.name,
                expireEndDate: this.endTime,
			}
			let flag = true;
			if(!data.name){
				flag = false;
                this.$toast({
                    message: "姓名不能为空"
                })
                return;
            }
            if(!data.idCard){
				flag = false;
                this.$toast({
                    message: "身份证不能为空"
                })
                return;
            }
			
			if( data.idCard.length != 15 && data.idCard.length != 18 ){
				flag = false;
                this.$toast({
                    message: "身份证格式有误"
                })
                return false;
            }
            if(data.expireEndDate == "请选择到期时间"){
				flag = false;
                this.$toast({
                    message: "请选择时间"
                })
                return;
            }
			return flag;
		}

	},
	created () {
		this.checkPlatform = getCheckPlatform();
		console.log(this.checkPlatform );
		if( this.checkPlatform == "E_SIGN" ){
			this.eSignFace = true;
		}else{
			this.oldSignFace = true;
		}
		let authInfo = JSON.parse(getAuthInfo());
		if( !authInfo.checkPass ){
			this.switchFlag = true;
		}
	},
};
</script>

<style scoped>
div{
	box-sizing: border-box;
}
.three-auth{
    padding-top: 50px;
    height: 100vh;
    position: relative;
    background: #fff;
}
.three-auth .main{
	padding: 5px 20px 0 20px;
}
.three-auth .item{
	height: 94px;
	border-bottom: 1px solid #EDF1F4;
	padding-top: 24px;
}
.three-auth .item .name{
	font-size: 14px;
	font-weight: 600;
}
.three-auth .item .inp{
	margin-top: 10px;
	color: #999999;
}
.three-auth .item .inp>span{
	display: inline-block;
	width: 50%;
}
.three-auth .item .inp input{
	width: 280px;
}
input::-webkit-input-placeholder{
	padding-top: 2px;
	line-height: normal ;
}
.three-auth .item .inp-code{
	margin-top: 10px;
	justify-content: space-between;
}
.three-auth .item .inp-code input{
	color: #999999;
}
.three-auth .item .inp-code span{
	color: #116ED9;
  word-break:keep-all;     /* 不换行 */
  white-space:nowrap;      /* 不换行 */
}
.three-auth .submit{
	margin-top: 50px;
}
.three-auth .submit button{
	width: 326px;
	height: 48px;
	border-radius: 24px;
	background: #0471FD;
	color: #fff;
}
.ta-btn{
	background: #0471FD;
}
</style>
